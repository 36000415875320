import React from 'react'
import Layout from '@/layouts/MainLayout'
import {
  Organizers,
  Categories,
  Introduction,
  Sponsors,
  Victorina,
} from '@/components'

import SEO from '../components/seo'
import { YMInitializer } from 'react-yandex-metrika'
import { RegModal, VideoModal } from '@/modals'

const IndexPage = () => {
  return (
    <Layout onMain={true}>
      <SEO title='Фестиваль науки' />
      <YMInitializer
        accounts={[68260900]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
        }}
      />
      <Introduction />
      <Categories />
      {/* решили что не нужно , но попросили оставить */}
      <Victorina />
      <Organizers />
      <Sponsors />
      <VideoModal />
      <RegModal />
    </Layout>
  )
}
export default IndexPage
